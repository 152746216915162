* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
  overscroll-behavior: none;
}
html, body {
  height: 100%;
}
#root {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;
  position: relative;
  overflow: hidden;
  padding-bottom: 182px;
  min-width: 1248px;
  min-height: 100%;
  width: 100%;
}

.about-us,
.privacy-policy,
.rules-of-conduct,
.terms-of-service {
  display: flex;
  flex-direction: column;
  margin-top: 150px;
  margin-right: 200px;
  margin-left: 200px;
  align-items: start;
  justify-content: start;
  font-size: 1rem;
}

.about-us {
  color: #fff;
}

.privacy-policy {
  color: #fff;
}

.rules-of-conduct {
  color: #fff;
}

.terms-of-service {
  color: #fff;
}

.terms-of-service > h1 {
  font-size: 3rem;
}

.privacy-policy > h1 {
  font-size: 3rem;
}

.rules-of-conduct > h1 {
  font-size: 3rem;
}