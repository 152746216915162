@font-face {
    font-family: 'AnonymousPro'; /*a name to be used later*/
    src: url('/public/fonts/AnonymousPro-Regular.ttf') format('truetype'); /*URL to font*/
    font-display: swap;
}
@font-face {
    font-family: 'AnonymousPro-Bold'; /*a name to be used later*/
    src: url('/public/fonts/AnonymousPro-Bold.ttf') format('truetype'); /*URL to font*/
    font-display: swap;
}

/* Code for Firefox */
/* ::-moz-selection {
    background: red;
}

::selection {
    background: red;
} */

.home-root {
    max-width: 1000px;
    background-color: black;
    display: grid;
    justify-content: center;
    align-items: start;
}

.home-container{
    width: 100%;
    z-index: 2;
    color: white;
    position: relative;
}

.download-container-top{
    width: 100%;
    height: 100px;
    padding-left: 70px;
    padding-right: 70px;
    color: white;
    display:flex;
    justify-content: end;
    text-align: center;
    position: relative;
}

.download-container-bottom{
    width: 100%;
    height: 100px;
    padding-left: 170px;
    padding-right: 70px;
    color: white;
    display:flex;
    justify-content: start;
    text-align: center;
    position: relative;
}

.home-gallery-description {
    padding-left: 70px;
    position: relative;
    color: white;
    justify-content: start;
    text-align: left;
}

.home-gallery-container {
    padding-top: 25px;
    padding-left: 70px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home-gallery-controller {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-right: 50px;
}