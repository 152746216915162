.navbar {
  background: linear-gradient(90deg, rgb(30, 30, 30) 0%, rgb(30, 30, 30) 100%);
  width: 100%;
  height: 80px;
  display: flex;
  position: fixed;
  top: -80px;
  transition: 0.3s ease-out;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.navbar.active {
  background: linear-gradient(90deg, rgb(30, 30, 30) 0%, rgb(30, 30, 30) 100%);
  width: 100%;
  height: 80px;
  display: flex;
  top: 0;
  position: fixed;
  transition: 0.3s ease-out;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.nav-item {
  height: 80px;
}

.NavbarItems {
  position: relative;
}

.nav-menu {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  width: 300px;
  height: 100%;
  position: fixed;
  top: 80px;
  right: -100%;
  opacity: 1;
  z-index: 998;
  transition: all 0.5s ease;
}

.nav-menu.active {
  position: fixed;
  background: rgb(17, 17, 17);
  right: 0;
  opacity: 1;
  z-index: 998;
  transition: all 0.5s ease;
}

.nav-links {
  color: #fff;
  display: table;
  text-align: center;
  text-decoration: none;
  padding: 2rem;
  width: 100%;
}

.nav-links:hover {
  background-color: #fff;
  color: #242424;
  border-radius: 0;
}

.menu-icon {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  transform: translate(-100%, 100%);
  cursor: pointer;
}